//LOADER
import $ from 'jquery';
import 'bootstrap';
import _ from 'underscore';
import 'babel-polyfill';

import './bootsnav.js';
import './jquery.parallax-1.1.3.js';

import '../css/bootstrap-custom.less';
import '../css/style.css';
import '../css/blue.css';
import '../css/video-js.css'
import '../css/webawards.less';

window.jQuery = window.$ = $;

import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
$(function () {
    Sentry.init({
        dsn: window.SENTRY_DSN,

        // Alternatively, use `process.env.npm_package_version` for a dynamic release version
        // if your build tool supports it.
        release: window.SENTRY_RELEASE,
        integrations: [new BrowserTracing()],
        environment: window.ENV,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: window.SENTRY_SAMPLE_RATE,

    });
})

// todo : refactor eurid_cloudflare so we can call this
let setUpAjaxCsrf = function() {
    $.ajax({
        url: window.csrfTokenUrl,
        method: 'GET',
        type: 'GET',
        success: function (data) {
            $('.custom-csrf-token').each(function () {
                let $this = $(this);
                let parentForm = $(this).closest('form');
                $this.replaceWith(`<input type="hidden" name="csrfmiddlewaretoken" value="${data.csrf}"/>`);
                $(parentForm).removeAttr('disabled');
                $(parentForm).find('*[type="submit"]').removeAttr('disabled');
            })

        }
    })
}

$(function () {
    let $window = $(window);

    setUpAjaxCsrf();
    // ------- Back to Top -------
    $("body").append('<a href="#" class="back-top"><i class="fa-solid fa-angle-up"></i></a>');
    let amountScrolled = 700;
    let backBtn = $("a.back-top");
    $window.on("scroll", function () {
        if ($window.scrollTop() > amountScrolled) {
            backBtn.addClass("back-top-visible");
        } else {
            backBtn.removeClass("back-top-visible");
        }
    });
    $(document.body).on('hidden.bs.modal', function () {
        backBtn.addClass("back-top-visible");
    });
    $(document.body).on('shown.bs.modal', function () {
        backBtn.removeClass("back-top-visible");
    });
    backBtn.on("click", function () {
        $("html, body").animate({
            scrollTop: 0
        }, 700);
        return false;
    });

    /* ------- Smooth scroll ------- */
    $(".scroll").on("click", function (event) {
        event.preventDefault();
        $("html,body").animate({
            scrollTop: ($(this.hash).offset().top - 10)
        }, 1000);
    });

    /* ------- Sidebar Toggle ------ */
    $(".menu-icon").on("click", function () {
        $(".overlay-menu").toggleClass("open");
    });
    if ($(".full-nav li a").hasClass("scroll")) {
        $(".full-nav li a").on("click", function () {
            $(".overlay-menu").removeClass("open");
        });
    }

    //Full Height Banner BG
    function resizebanner() {
        $("#text-rotate").css("height", $(window).height());
    }

    $(window).resize(function () {
        resizebanner();
    });
    resizebanner();


    /*Toggle POPOVER for share buttns*/
    $(".share").on("click", function () {
        $(".share_purpose").fadeToggle();
    });

    // =========== Parallax ===========
    if ($window.width() > 1030) {
        $(".parallax-bg").parallax("50%", 0.1, 50);
        $(".page-header").parallax("50%", 0.2);
    }

    /* Banner video */
    let backgroundVideoNodes = $('.background-video');
    if (backgroundVideoNodes.length) {
        backgroundVideoNodes.each(function (_, node) {
            $(node).prepend(createVideoNode(node));
        });
    }

    $(".loader").fadeOut(800, function () {
        $("#maxo-main").addClass('show');
    });

    function createVideoNode(nativeNode) {
        let nodeData = $(nativeNode).data();

        if (!nodeData.videoUrls) {
            return $('<video />');
        }

        // Prevent loading the videos on mobile browsers as its likely that they
        // are on low-bandwidth connections.
        let isMobile = window.matchMedia && window.matchMedia("only screen and (max-width: 760px)").matches;
        isMobile = isMobile
            || navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i);

        if (isMobile) {
            if (nodeData.posterUrl) {
                return $('<video />').css('background-image', 'url(' + nodeData.posterUrl + ')').css('width', '100%');
            } else {
                return $('<video />');
            }
        }

        let videoURLs = nodeData.videoUrls.split(',');
        let sourceNodes = videoURLs.map(function (url) {
            return $('<source />').attr({
                src: url,
                'data-wf-ignore': '',
            });
        });

        let videoNode = $('<video />').attr({
            autoplay: nodeData.autoplay,
            loop: nodeData.loop,
        });
        // if (nodeData.posterUrl) {
        //     videoNode.css('background-image', 'url(' + nodeData.posterUrl + ')');
        // }

        let $window = $(window);
        $window.on("scroll", function () {
            if ($window.scrollTop() > window.innerHeight) {
                !videoNode[0].paused && videoNode[0].pause();
            } else {
                videoNode[0].paused && videoNode[0].play();
            }
        });

        videoNode.append(sourceNodes);
        return videoNode;
    }

    /* Tooltip */
    $('[data-toggle="tooltip"]').tooltip();

    /* Navbar */
    $('.navbar-collapse li > a').on('click', function () {
        $(".navbar .navbar-toggle .fa").removeClass("fa-times");
        $(".navbar .navbar-toggle .fa").addClass("fa-bars");
        $('#navbar-menu').collapse('hide');
    });

    /* Nominations */

    let allNomineesModal = $('#all-nominees-modal');
    let nominationForm = $('#nomination-form');
    let nomineeTmpl = _.template(document.getElementById('nominee-template').innerHTML);
    let nomineeRowTmpl = _.template(document.getElementById('nominee-row-template').innerHTML);
    let nomineesContainer = $('#nominees-container');
    let categoriesIndex = $('#categories-index');

    /* Nomination form */
    nominationForm.find('input,select').blur(function () {
        $(this).addClass('check');
    });

    function validateForm() {
        let valid = true;
        nominationForm.find('input,select').addClass('check').each(function (valid, elem) {
            this.setCustomValidity('');
            valid = valid && elem.checkValidity();
        });
        return valid;
    }

    function showMessage(status, message) {
        let modal = $('#modal-message-' + status);
        modal.find('.message').html(message);
        modal.modal('show');
    }

    function showSuccess(message, shareUrl) {
        if (shareUrl) {
            let shareBtns = '<a href="https://facebook.com/sharer/sharer.php?u=' + encodeURIComponent('https://webawards.eurid.eu/?q=' + shareUrl) + '"><i class="fa-brands fa-facebook"></i></a>';
            shareBtns += '<a href="https://x.com/intent/tweet?text=Vote for ' + shareUrl + ' for the 2023 .eu Web Awards on webawards.eurid.eu/?q=' + encodeURIComponent(shareUrl) + '&hashtags=2023euWA"><i class="fa-brands fa-x-twitter"></i></a>';
            shareBtns += '<a href="https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent('https://webawards.eurid.eu/?q=' + shareUrl) + '"><i class="fa-brands fa-linkedin"></i></a>';
            showMessage('success', '<div><p>' + message + '</p><div class="social">' + shareBtns + '</div></div>');
        } else {
            showMessage('success', message);
        }
    }

    function showError(message) {
        showMessage('error', message);
    }

    nominationForm.find('button[type=submit]').click(function () {
        validateForm();
    });
    $('input[type=radio][name=category]').change(function () {
        let cat = $('input[type=radio][name=category]:checked').val();
        let description = $('.panel.nominees-filter[data-filter=' + cat + '] p').html();
        $('form .category-description').html(description);
    });

    nominationForm.submit(function (evt) {
        evt.preventDefault();

        if (!validateForm()) {
            return;
        }

        nominationForm.find('.modal-content').addClass('spin-loader');
        nominationForm.find('.help-block.text-danger').remove();

        $.ajax({
            url: '/api/nominees',
            type: 'POST',
            data: new FormData(nominationForm[0]),
            contentType: false,
            processData: false,
        }).then(function () {
            nominationForm.find('.modal-content').removeClass('spin-loader');
            nominationForm.modal('hide');
            showSuccess('Thanks for submitting your nomination! It will be verified in the coming days. Once confirmed, make sure to spread the word to gather as many votes as possible.');
        }, function (data) {
            nominationForm.find('.modal-content').removeClass('spin-loader');
            if (data.responseJSON) {
                Object.entries(data.responseJSON).forEach(function (field) {
                    nominationForm.find('input[name=' + field[0] + '],select[name=' + field[0] + ']').each(function () {
                        this.setCustomValidity(field[1].join(''));
                        $(this).closest('.form-group').append('<div class="help-block text-danger">' + field[1] + '</div>');
                    });
                });
            } else {
                showError(data.status === 522 ? 'This feature is temporarily unavailable. Please come back later. Thanks for your patience.' : 'Oops! An error has occurred.');
            }
        });
    });

    function domainNameToUrl(url) {
    if (!url?.startsWith("http://") && !url?.startsWith("https://")){
        url = "https://" + url;
        }
    return url;
    }

    function urlToDomainName(url) {
        url = domainNameToUrl(url);
        return url.split('/')[2];
    }

    function formatCategory(category) {
        category = category.replaceAll('-', ' ').substring(0, 1).toUpperCase()
            + category.replaceAll('-', ' ').substring(1, category.length)
        // category = category.replace('idns', 'IDNs');
        category = category.replace(' eu', ' .eu');
        return category;
    }

    /* load nominees */
    function loadNominees(page, slide) {
        nomineesContainer.addClass('spin-loader');
        page = page || 1;

        let q = $('#search-nominees').val() || '';
        let cat = $('.cbp-filter-item-active').data('filter') || '';

        $.get('/api/nominees?q=' + q + '&category=' + cat + '&page=' + page).then(function (data) {
            nomineesContainer.removeClass('spin-loader');

            if (categoriesIndex.is(':visible')) {
                categoriesIndex.hide();
            }
            if (!nomineesContainer.is(':visible')) {
                nomineesContainer.show();
            }

            if (data && data.results.length) {
                let newRow = $('<div class="row"></div>');
                let oldRow = nomineesContainer.find('.row');
                data.results.forEach(function (nominee) {
                    let html = nomineeTmpl(Object.assign(nominee, {
                        url: domainNameToUrl(nominee.domain_name),
                        domain_name: urlToDomainName(nominee.domain_name)
                    }));
                    newRow.append(html);
                });
                if (slide) {
                    newRow.addClass('slide-' + slide);
                    oldRow.remove();
                    newRow.on('transitionend', function () {
                        newRow.removeClass('slide-' + slide).removeClass('in');
                    });
                    nomineesContainer.prepend(newRow);
                    setTimeout(function () {
                        newRow.addClass('in');
                    });
                } else {
                    newRow.hide().fadeIn();
                    oldRow.remove();
                    nomineesContainer.prepend(newRow);
                }
                if (data.previous) {
                    nomineesContainer.find('.prev').show().data('page', page - 1);
                } else {
                    nomineesContainer.find('.prev').hide();
                }
                if (data.next) {
                    nomineesContainer.find('.next').show().data('page', page + 1);
                } else {
                    nomineesContainer.find('.next').hide();
                }
                setUpAjaxCsrf();
            } else {
                nomineesContainer.find('.row').html('<h3 class="text-center col-xs-12">No websites have been nominated for this category yet.</h3>');
                nomineesContainer.find('.prev').hide();
                nomineesContainer.find('.next').hide();
            }
        }, function (jqxhr) {
            nomineesContainer.removeClass('spin-loader');
            let message = jqxhr.responseJSON && jqxhr.responseJSON.detail ? jqxhr.responseJSON.detail : 'Oops! An error has occurred.';
            if (jqxhr.status === 522) {
                message = 'This feature is temporarily unavailable. Please come back later. Thanks for your patience.';
            }
            nomineesContainer.find('.row').html('<h3 class="text-center col-xs-12">' + message + '</h3>');
            nomineesContainer.parent().find('button[data-target="#nomination-form"]').prop('disabled', true).addClass('disabled');
        });
    }

    /* load all nominees in modal */
    function loadAllNominees() {
        let allNomineesModalBody = allNomineesModal.find('.modal-body');
        if (allNomineesModal.data('next')) {
            return
        } else {
            allNomineesModalBody.data('next', '/api/nominees?page=1');
        }
        allNomineesModalBody.find("#error-message").remove();
        let loading = false;

        function loadMoreNominees() {
            if (loading) return;
            let scrollTop = allNomineesModalBody.prop("scrollTop");
            let clientHeight = allNomineesModalBody.prop("clientHeight");
            let scrollHeight = allNomineesModalBody.prop("scrollHeight");
            if (
                (scrollTop + clientHeight >= scrollHeight - 5 || scrollHeight <= clientHeight) &&
                !loading &&
                !allNomineesModalBody.data('fully-loaded')) {
                loading = true;
                let next = allNomineesModalBody.data("next");
                allNomineesModalBody.append('<div class="spin-loader" style="min-height: 50px"></div>');
                allNomineesModalBody.find("#error-message").remove();
                $.get(next).then(function (data) {
                    allNomineesModalBody.find(".spin-loader").remove();
                    if (data?.results?.length) {
                        allNomineesModalBody.data("next", data.next);
                        if (data.next === null) {
                            allNomineesModalBody.data('fully-loaded', true);
                        }
                        data.results.forEach(function (nominee) {
                            let row = $('<div class="row"></div>');
                            let html = nomineeRowTmpl(Object.assign(nominee, {
                                url: domainNameToUrl(nominee.domain_name),
                                domain_name: urlToDomainName(nominee.domain_name),
                                category: formatCategory(nominee.category)
                            }));
                            row.append(html);
                            allNomineesModalBody.append(row);
                        });
                    }
                    else{
                        allNomineesModalBody.data('fully-loaded', true);
                        if(!allNomineesModalBody.html().trim()){
                            allNomineesModalBody.text('No websites have been nominated yet.')
                        }
                    }
                    loading = false;
                    setUpAjaxCsrf();
                    if (scrollHeight <= clientHeight) loadMoreNominees();
                }, function (jqxhr) {
                    setTimeout(function () {
                        let message = jqxhr.responseJSON && jqxhr.responseJSON.detail ? jqxhr.responseJSON.detail : 'Oops! An error has occured.';
                        if (jqxhr.status === 522) {
                            message = 'This feature is temporarily unavailable. Please come back later. Thanks for your patience.';
                        }
                        allNomineesModalBody.find(".spin-loader").remove();
                        allNomineesModalBody.append('<div id="error-message" class="text-center col-xs-12 top10">' + message + ' <a role="button" id="retry">Retry</buttoni></div>');
                        allNomineesModalBody.find("#retry").off().on("click", function () {
                            loadMoreNominees();
                            allNomineesModalBody.on("resize scroll", function () {
                                loadMoreNominees();
                            });
                        });
                        loading = false;
                    }, 500);
                    allNomineesModalBody.off();
                });
            }
        }

        loadMoreNominees();
        allNomineesModalBody.on("resize scroll", function () {
            loadMoreNominees();
        });
    }

    $('#all-nominees-modal').on('shown.bs.modal', function () {
        loadAllNominees();
    });

    /* nominees vote */
    allNomineesModal.on('submit', '.form-vote-modal', function (e) {
        e.preventDefault();
        e.stopPropagation();
        let nomineeUrl = $(this).closest('.nominee-row').data('url');
        let $this = $(this);

        $.ajax({
            url: $this.attr('action'),
            type: "POST",
            data: $this.serialize()
        }).then(function (data) {
            showSuccess(data.detail, nomineeUrl);
        }, function (jqxhr) {
            if (jqxhr.status === 522) {
                showError('This feature is temporarily unavailable. Please come back later. Thanks for your patience.');
            } else {
                if(!(jqxhr.responseJSON && jqxhr.responseJSON.detail)) {
                        Sentry.captureException(
                            new Error('Vote call got an unexpected error'),
                            {
                                extra: {
                                    response: jqxhr,
                                }
                            }
                        )
                    }
                showError((jqxhr.responseJSON && jqxhr.responseJSON.detail) || 'Oops! An error has occured.');
            }
        });
    });

    allNomineesModal.on('click', 'button[type="submit"]', function(e){
      e.preventDefault();
      e.stopPropagation();
      $(this).closest('form').submit()
    })


    $('.nominees-filter').click(function (evt) {
        $('#search-nominees').val(''); // reset search filter
        $('.cbp-filter-item').removeClass('cbp-filter-item-active');
        let selectedCat = $(evt.currentTarget).data('filter');
        $('[data-filter="' + selectedCat + '"]').addClass('cbp-filter-item-active');
        loadNominees();
    });

    $('.categories-index-btn').click(function () {
        $('.cbp-filter-item').removeClass('cbp-filter-item-active');
        $('.categories-index-btn').addClass('cbp-filter-item-active');

        if (nomineesContainer.is(':visible')) {
            nomineesContainer.hide();
            categoriesIndex.fadeIn();
        }
    });

    let timer;
    $('#search-nominees').on('keypress', function () {
        clearTimeout(timer);
        timer = setTimeout(function () {
            loadNominees();
        }, 600);
    });
    nomineesContainer.find('.next').click(function () {
        loadNominees($(this).data('page'), 'left');
    });
    nomineesContainer.find('.prev').click(function () {
        loadNominees($(this).data('page'), 'right');
    });

    /* nominees vote */
    nomineesContainer.on('submit', '.form-vote', function (e) {
        nomineesContainer.addClass('spin-loader')
        e.preventDefault();
        let $this = $(this);
        let nomineeUrl = $(this).closest('.nominee-card').data('url');

        nomineesContainer.addClass('spin-loader');
        $.ajax({
            url: $this.attr('action'),
            type: "POST",
            data: $this.serialize(),
            success: function (data) {
                nomineesContainer.removeClass('spin-loader');
                showSuccess(data.detail, nomineeUrl);
            },
            error: function (jqxhr) {
                nomineesContainer.removeClass('spin-loader');
                if (jqxhr.status === 522) {
                    showError('This feature is temporarily unavailable. Please come back later. Thanks for your patience.');
                } else {
                    if(!(jqxhr.responseJSON && jqxhr.responseJSON.detail)) {
                        Sentry.captureException(
                            new Error('Vote call got an unexpected error'),
                            {
                                extra: {
                                    response: jqxhr,
                                }
                            }
                        )
                    }
                    showError((jqxhr.responseJSON && jqxhr.responseJSON.detail) || 'Oops! An error has occurred.');
                }
            }
        });
    });

    $('#nominees').on('click', '.social a', function () {
        window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
        return false;
    });

    function getUrlParameter(name) {
        let pageURL = decodeURIComponent(window.location.search.substring(1));
        let params = pageURL.split('&');

        return params.map(function (param) {
            let paramParts = param.split('=');
            return {
                name: paramParts[0],
                value: paramParts[1]
            };
        }).filter(function (param) {
            return param.name === name;
        }).map(function (param) {
            return param.value;
        })[0];
    }

    let query = getUrlParameter('q');

    function scrollspyNominees() {
        let section = $(".nav li.active > a").attr('href');
        if (section === '#nominees' || section === '#how-it-works') {
            if(!query){
                loadNominees();
            }
            $('.nav').off('activate.bs.scrollspy', scrollspyNominees);
        }
    }

    if (query) {
        $('#search-nominees').val(query);
        loadNominees();
        $("html,body").animate({
            scrollTop: $('#nominees').offset().top - 10
        }, 1000);
    } else {
        $('.nav').on('activate.bs.scrollspy', scrollspyNominees);
    }
})
;

$(window).on("load", function () {
    let dt = new Date();
    document.getElementById('year-date-copyright').innerHTML = dt.getFullYear();
});

$(window).on("load", function () {
    if ($('#winners-modal').length) {
        $('#winners-modal').modal('show');
        $('#modal-link-banner').click(function () {
            $('#winners-modal').modal('toggle');
        });
    }
});

$(window).on("CookiebotOnLoad", function () {
    if (Cookiebot.consent.statistics) {
        let embedCode = '<iframe src="https://vimeo.com/event/512302/embed" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>'

        if (!$(".vimeo-container > iframe").length) {
            $('.vimeo-container').html(embedCode);
        }

        setTimeout(function () {
            if ($(".vimeo-container > iframe").data("cookieblock-src")) {
                $(".vimeo-container > iframe").attr("src",
                    $(".vimeo-container > iframe").data("cookieblock-src"));
            }
        }, 1000);

    } else {
        let vimeoAlt = '<div class="vimeo-alt">';
        vimeoAlt += 'The event cannot be displayed. <br> To display the event, please change your <a href="javascript: Cookiebot.renew();">cookie settings</a>';
        vimeoAlt += ' to accept the statistics cookies or follow the <a href="https://vimeo.com/event/512302" target="_blank"> event on Vimeo</a>.'
        vimeoAlt += '</div>';
        $('.vimeo-container').html(vimeoAlt);
    }
});